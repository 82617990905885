.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v15/oMMgfZMQthOryQo9n22dcuvvDin1pK8aKteLpeZ5c0A.woff2) format('woff2');
}

@font-face {
  font-family: 'Asap';
  font-style: normal;
  font-weight: 400;
  src: local('Asap'), local('Asap-Regular');
}

html, body, input, button {
  font-family: 'Asap', sans-serif;
}

body {
  height: 100vh;
  background-image: url('./assets/logo_background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;
}

.app-wrapper {
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px 0 0;
}

h1 {
  text-align: center;
  color: white;
  font-weight: normal;
  letter-spacing: 0.02em;
}

.vertical-padding {
  padding: 0 15px;
}

#react-target {
  height: 100%;
  width: 100%;
}

.header-main-bar {
  height: 415px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #00355ac9;
}

#header-logo {
  height: 74px;
  width: 30px;
  margin-left: calc(50% - 15px);
  background-image: url('./assets/planta_logo_only_neg_inkscape.svg');
  background-repeat: no-repeat;
  background-size: 30px;
  margin-top: 45px;
}

.main-title-container {
  margin-top: 75px;
  margin-bottom: 35px;
  position: relative;
}

.sub-header {
  color: white;
  font-size: 16px;
  letter-spacing: 0.02em;
  text-align: center;
}

.contentBox {
  position: relative;
  background: white;
  width: 600px;
  margin-left: calc(50% - 300px);
  border-radius: 5px;
  padding: 5px 5px 15px;
  box-shadow: 0 1px 2px 0 lightgray;
  text-align: center;
}

input {
  font-size: 14px;
  height: 35px;
  width: 60%;
  margin: 0 20%;
  padding: 0 20px;
  border: 1px solid #00355a5c;
  border-radius: 3px;
}

input:focus {
  border-color: #00355a;
}

input#code-input {
  width: 160px;
  margin: 0 auto;
}

.relative-container {
  position: relative;
}

.name-container {
  display: flex;
  margin-bottom: 15px;
  justify-content: center;
}

.firstName-container {
  margin-right: 10px;
}

.domain-container, .password-container, .email-container, .company-container {
  display: inline-block;
}

#firstName-input, #secondName-input {
  margin: 0;
  width: 195px;
}

#password-input, #domain-input, #email-input, #phone-input, #company-input {
  margin: 0;
  width: 440px;
}

.fa-wrapper {
  position: absolute;
  right: 0;
  padding: 12px;
  color: #00806e;
  font-size: 12px;
}

.fa-wrapper.error {
  color: #d13f19;
}

.btn-container {
  padding: 0 55px;
}

button {
  display: inline-block;
  background: #f19822;
  background-image: -webkit-linear-gradient( bottom, #d8830c, #f19822);
  background-image: -moz-linear-gradient( bottom, #d8830c, #f19822);
  background-image: -o-linear-gradient( bottom, #d8830c, #f19822);
  background-image: linear-gradient( to top, #d8830c, #f19822);
  border-radius: 30px;
  border: none;
  color: white;
  font-size: 16px;
  width: 200px;
  margin: 15px 10px 10px;
  padding: 8px 10px;
}

button:hover {
  cursor: pointer;
  background: #C06E01;
  background-image: -webkit-linear-gradient( bottom, #992e00, #C06E01);
  background-image: -moz-linear-gradient( bottom, #992e00, #C06E01);
  background-image: -o-linear-gradient( bottom, #992e00, #C06E01);
  background-image: linear-gradient( to top, #992e00, #C06E01);
}

.label {
  text-align: start;
  padding: 16px 2px 8px;
  color: rgb(38 59 95);
  font-size: 0.875rem;
  font-weight: 500;
}

.label > div::after {
  color: rgb(221, 58, 17);
  content: "*";
  display: inline-block;
  padding-left: 4px;
}

.sub-label {
  text-align: start;
  padding: 0 2px 7px;
  color: grey;
  font-size: 0.75rem;
  line-height: 22px;
}

.content-header {
  font-size: 1.25rem;
  line-height: 2;
  margin: 20px 0;
}

.content-header > i {
  color: #00355a;
}

.smaller {
  font-size: smaller;
}

.info-label {
  font-size: 12px;
  padding: 10px 0 5px;
}

.requiredStar {
  color: red;
}

.link {
  color: blue;
  display: inline-block;
}

.link:hover {
  cursor: pointer;
  color: #0089ff;
}

.quiet-link {
  display: inline-block;
  color: darkgrey;
}

.quiet-link:hover {
  cursor: pointer;
  color: grey;
}

.error {
  color: #d13f19;
}

.done-icon {
  font-size: 32px;
  margin-bottom: 20px;
}

/* Tippy tooltip theme */
.tippy-box[data-theme~='pulse'] {
  background-color: #00355a;
  color: white;
  padding: 1px 5px;
}
.tippy-box[data-theme~='pulse'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: #00355a;
}
.tippy-box[data-theme~='pulse'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: #00355a;
}
.tippy-box[data-theme~='pulse'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: #00355a;
}
.tippy-box[data-theme~='pulse'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: #00355a;
}

/* Mobile view */
@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }

  .contentBox {
    width: calc(100% - 4px);
    margin-left: 2px;
    padding: 5px 0 15px;
  }

  #firstName-input, #secondName-input {
    margin: 0 15px;
    padding: 0 5px;
    width: calc(100% - 40px);
  }

  #password-input, #domain-input, #email-input, #phone-input {
    margin: 0 15px;
    padding: 0 5px;
    width: calc(100% - 40px);
  }

  .domain-container, .password-container, .email-container, .company-container {
    display: block;
    width: 100%;
  }

  .label, .sub-label {
    margin: 0 15px;
  }

  .fa-wrapper {
    right: 5px;
  }

  .footer {
    margin-left: 0;
  }

  .footer-logo {
    display: none;
  }

  .btn-container {
    padding: 0;
  }

  .firstName-container {
    margin-right: 0;
  }

  .firstName-container, .secondName-container {
    width: 50%;
  }
}
