.footer-container {
  position: fixed;
  display: inline-block;
  background: #00355a;
  background-color: #00355a;
  height: auto;
  width: 100%;
  bottom: 0;
}

.footer {
  text-align: center;
  display: flex;
  margin-left: 8%;
  font-size: 16px;
  letter-spacing: 0.05em;
}

.footer > a {
  display: inline-block;
  margin: 0 35px;
  color: white;
  text-decoration: none;
  padding: 35px 0;
}

.footer > a:hover {
  text-decoration: underline;
}

.footer-logo {
  height: 65px;
  padding-top: 17px;
  margin-right: 25px;
}

.footer > svg:hover {
  cursor: pointer;
}

.lang-setting {
  font-size: 12px;
  letter-spacing: 1px;
}

@media only screen and (max-height: 895px) {
  .footer-container.first-page {
    position: relative;
    margin-top: 97px;
  }
}

/* Different margins on second view */
@media only screen and (max-height: 660px) {
  .footer-container.second-page {
    position: relative;
    margin-top: 97px;
  }
}
